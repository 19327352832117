.headerPrefix
	+container
	--container-width: 86em
	--container-padding-x: 1em
	display: flex
	border-bottom: 1px solid hsl(0, 0%, 93%)
	padding-top: 0.5em
	font-size: 14px
	+dark
		border-bottom-color: hsl(0, 0%, 30%)
	&-portal
		flex-grow: 1
	&-auth
		flex-shrink: 0
		+vw-9-down
			display: none
		a
			+link
			text-decoration: none
.portal
	display: flex
	align-items: center
	&-item
		display: flex
		align-items: center
		border-y: 2px solid transparent
		padding: 0.3em 1.7em
		cursor: default
		+vw-5-down
			padding: 0.3em
		@at-root a#{&}
			color: inherit
			text-decoration: none
			cursor: pointer
			transition:
				property: background-color
				duration: 0.3s
			&:hover
				background-color: rgba($color-red, 0.03)
		&:not(:first-child)
			position: relative
			margin-left: 1.4em
			&::after
				content: ''
				absolute: top left -1.4em
				display: block
				width: 1px
				height: 100%
				padding: 0.35em 0
				background-color: hsl(0, 0%, 51%)
				background-clip: content-box
		&:not(:last-child)
			margin-right: 1.4em
		&._active
			border-bottom-color: $color-red
		img
			display: block
			margin-right: 0.5em
			height: 0.75em
			width: auto
			+vw-6-down
				display: none
			+dark
				filter: invert(1)
		span
			font-size: 0.85em
.header
	+container
	--container-width: 86em
	--container-padding-x: 1em
	display: flex
	padding-y: 0.5rem
	font-size: 14px
	+vw-9-down
		align-items: center
	&-logo
		flex-shrink: 0
		font-size: 1em
		margin-y: 0.5rem
		margin-right: 0.5em
		> span
			+visually-hidden
		img
			display: block
	&-auth
		margin-left: auto
		+vw-9-up
			display: none
		a
			+link
			text-decoration: none
	&-nav
		flex-grow: 1
		display: flex
		justify-content: flex-end
		flex-wrap: wrap
		+vw-9-down
			display: none
.headerNav
	&-link
		display: inline-block
		a
			+link
			display: inline-block
			padding: 1em 0.5em
			color: inherit
			text-decoration: none
			+vw-11-up
				padding: 1em
