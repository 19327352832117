.warrantyContainer
	background-color: var(--color-blue)
.warranty
	+container
	position: relative
	padding: 1em
	+vw-7-up
		background:
			image: url('partials/warranty/images/warranty-ribbon.svg')
			position: right center
			size: contain
	+vw-7-down
		padding-right: 8em
		&::after
			content: ''
			absolute: bottom -2em right 1em
			display: block
			size: 8em
			background:
				image: url('partials/warranty/images/stamp.svg')
				size: contain
	+vw-5-down
		padding-right: 0
		padding-bottom: 2em
		&::after
			size: 5em
	&-title
		margin-y: 0.1em
		max-width: 25em
		font-size: var(--font-size-h3)
	&-text
		max-width: 30em
