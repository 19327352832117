\:root
	--container-width: #{$container-width}
	--container-padding-x: 1em

	--font-size-h1: 3.5em
	--font-size-h2: 3em
	--font-size-h3: 2.25em
	--font-size-h4: 1.5em

	--color-gray: #{$color-gray}
	--color-gray-focus: #{$color-gray-focus}

	--color-red: #{$color-red}
	--color-red-accent: #{$color-red-accent}
	--color-red-hover: #{$color-red-hover}

	--color-red-text: #{$color-red-text}

	--color-blue: #{$color-blue}
	--color-blue-accent: #{$color-blue-accent}
	--color-blue-hover: #{$color-blue-hover}
	--color-blue-focus: #{$color-blue-focus}

	+dark
		--color-gray: #{$color-gray-at-dark}
		--color-gray-focus: #{$color-gray-focus-at-dark}

		--color-red: #{$color-red-at-dark}
		--color-red-accent: #{$color-red-accent-at-dark}
		--color-red-hover: #{$color-red-hover-at-dark}

		--color-red-text: #{$color-red-text-at-dark}

		--color-blue: #{$color-blue-at-dark}
		--color-blue-accent: #{$color-blue-accent-at-dark}
		--color-blue-hover: #{$color-blue-hover-at-dark}
		--color-blue-focus: #{$color-blue-focus-at-dark}

	+vw-5-down
		--font-size-h1: 1.7em
		--font-size-h2: 1.6em
		--font-size-h3: 1.5em
		--font-size-h4: 1.2em
