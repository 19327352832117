$modal-z: 1000
.modalModel
	position: absolute
	display: none
	@each $name in $modals-list
		&[data-modal='modal-#{$name}']
			&:checked
				~ .modal[id='modal-#{$name}']
					visibility: visible
					opacity: 1
					transform: translateY(0)
					//transition:
						delay: 0s
					.modal-backdrop
						opacity: 1
						//transition:
							delay: 0.3s
.modal
	--modal-max-width: 40em
	fixed: full z ($modal-z + 1)
	visibility: hidden
	opacity: 0
	pointer-events: none
	transform: translateY(10%)
	transition:
		property: visibility, opacity, transform
		duration: 0.3s
		//delay: 0.7s
	will-change: opacity, transform
	+vw-5-down
		transition:
			duration: 0.5s
	&-body
		absolute: full
		display: flex
		flex-flow: column
		justify-content: center
		align-items: center
		overflow: auto
		pointer-events: auto
		+vw-5-down
			justify-content: flex-end
	&-outer
		width: 100%
		max-width: var(--modal-max-width)
		min-height: 0
	&-inner
		--modal-background-color: var(--body-bg)
		--modal-text-color: var(--body-color)
		relative: z 2
		border-radius: 5px
		box-shadow: 0 11px 15px -7px rgba(black, 0.2), 0 24px 38px 3px rgba(black, 0.14), 0 9px 46px 8px rgba(black, 0.12)
		background:
			color: var(--body-bg)
		color: var(--body-color)
		+dark
			--body-bg: hsl(0, 0%, 26%)
			--body-color: white
	&--close
		sticky: top z 10
		height: 0
		width: 100%
		max-width: var(--modal-max-width)
	&-close
		absolute: top right
		display: block
		border-bottom-left-radius: 5px
		size: 4.5em
		cursor: pointer
		background-color: rgba(black, 0)
		transition:
			property: background-color
			duration: 0.3s
		&:hover
			background-color: rgba(black, 0.1)
		&::before
			content: ''
			absolute: full
			padding: 1.5em
			background:
				image: url('components/modal/cross.svg')
				size: contain
				position: center
				clip: content-box
			+dark
				filter: invert(1)
	&-backdrop
		fixed: full z 1
		opacity: 0
		background:
			color: rgba(black, 0.4)
		transition:
			property: opacity
			duration: 0.7s
			//delay: 0s
		will-change: opacity
		transform: scale(2)
