.targeting
	$gap: 1em
	+container
	+vw-6-up
		display: grid
		grid-template-columns: auto auto
		grid-gap: $gap
	&-item
		background-color: var(--color-blue)
		border-radius: 10px
		overflow: hidden
		background:
			position: right top
			size: contain
		+vw-6-down
			margin-bottom: $gap
		&_customers
			+vw-6-up
				grid-row: 1
				grid-column: 1
			+vw-10-up
				background-image: url('partials/targeting/images/cart.svg')
				+dark
					background-image: url('partials/targeting/images/at-dark/cart.svg')
		&_authors
			+vw-6-up
				grid-row: 1
				grid-column: 2
			+vw-10-up
				background-image: url('partials/targeting/images/feather-pen.svg')
				+dark
					background-image: url('partials/targeting/images/at-dark/feather-pen.svg')
		&_special
			+vw-6-up
				grid-row: 2
				grid-column: 1 / -1
			+vw-10-down
				background:
					size: 25em
					position: bottom right
			+vw-6-down
				padding-bottom: 6em
			+vw-5-down
				background:
					size: 20em
					position: bottom right -6em
			background-image: url('partials/targeting/images/like.svg')
			+dark
				background-image: url('partials/targeting/images/at-dark/like.svg')
.targetingItem
	padding: 1.5em
	+vw-10-up
		padding-right: 10em
	&-title
		margin-y: 0 0.5em
		font-size: 1.5em
		font-weight: 700
		+vw-7-down
			font-size: 1.125em
	&-icon
		margin-left: 1em
		+vw-10-up
			display: none
	&-text
		margin-y: 0
		+vw-10-up
			margin-bottom: 1.5em
.targetingItemLinks
	+vw-10-up
		display: flex
	&-item
		margin-y: 0
		+vw-10-down
			margin-y: 1em
		&:not(:last-child)
			+vw-10-up
				margin-right: 3em
			+vw-11-up
				margin-right: 5em
		> a
			+link
