$font-sans: Montserrat, system-ui;

$container-width: 70em;

// Colors

$body-bg: white;
$body-color: black;

$body-bg-at-dark: hsl(0, 0%, 15%);
$body-color-at-dark: hsl(0, 0%, 90%);

$color-gray: hsl(0, 0%, 95%);
$color-gray-focus: hsl(0, 0%, 85%);

$color-red: hsl(338, 100%, 35%);
$color-red-accent: hsl(338, 100%, 55%);
$color-red-hover: hsl(338, 100%, 45%);

$color-red-text: $color-red;
$color-red-text-hover: lighten($color-red-text, 10);

$color-blue: hsl(203, 100%, 96%);
$color-blue-hover: hsl(202, 100%, 98%);
$color-blue-accent: hsl(215, 100%, 88%);
$color-blue-focus: hsl(216, 74%, 44%);

// Colors at dark

$color-gray-at-dark: hsl(0, 0%, 20%);
$color-gray-focus-at-dark: hsl(0, 0%, 35%);

$color-red-at-dark: $color-red;
$color-red-accent-at-dark: hsl(338, 100%, 65%);
$color-red-hover-at-dark: hsl(338, 100%, 45%);

$color-red-text-at-dark: hsl(338, 100%, 63%);
$color-red-text-hover-at-dark: lighten($color-red-text-at-dark, 20);

$color-blue-at-dark: hsl(202, 9%, 18%);
$color-blue-hover-at-dark: hsl(203, 9%, 25%);
$color-blue-accent-at-dark: hsl(215, 17%, 24%);
$color-blue-focus-at-dark: hsl(217, 32%, 41%);

// Modals

$modals-list: (
	'service-copywriting',
	'service-rewriting',
	'service-translate',
	'service-seo-texts',
	'service-mass-media',
	'service-naming',
	'service-reviews',
	'service-products-descriptions',
	'service-micro-tasks',
	'longread',
);
