\:root
	--body-bg: #{$body-bg}
	--body-color: #{$body-color}
	+dark
		--body-bg: #{$body-bg-at-dark}
		--body-color: #{$body-color-at-dark}
html
	font-family: $font-sans
	scroll-behavior: smooth
body
	margin: 0
	background-color: var(--body-bg)
	color: var(--body-color)
*
	&,
	&::before,
	&::after
		background:
			repeat: no-repeat
a
	color: inherit
