.responses
	--gap: 1.25em
	--container-width: 82.7em
	--container-padding-x: 2em
	+container
	display: flex
	flex-flow: column
	align-items: center
	+vw-7-down
		--container-padding-x: 0
		overflow: hidden
		padding-bottom: 2em
	&-title
		font-size: var(--font-size-h3)
	&-carousel
		max-width: 100%
	&-action
		margin-top: 2em
.responsesCarousel
	position: relative
	&:not(.js-carousel)
		%noJS-responsesCarousel-list
			display: flex
			overflow: auto
		%noJS-responsesCarousel-item
			width: 25em
			+vw-6-down
				width: 85vw
		%noJS-responsesCarousel-arrow
			display: none
	&-list
		@extend %noJS-responsesCarousel-list
		+vw-7-up
			border-radius: 10px
	&-item
		@extend %noJS-responsesCarousel-item
		flex-shrink: 0
		display: flex
		flex-flow: column
		padding-x: calc(var(--gap) / 2)
		opacity: 1
		transition: opacity 0.3s
		will-change: opacity
		&[data-carousel-edge]
			opacity: 0.5
			+dark
				opacity: 0.3
	&-arrow
		@extend %noJS-responsesCarousel-arrow
		$width: 5em
		--side: calc(var(--gap) / 2 - #{$width} / 2)
		absolute: top calc(50% - #{$width / 2}) z 1
		border-radius: 100%
		border: none
		size: $width
		background-color: var(--body-bg)
		+vw-10-down
			--side: 0
			top: -6em
		+vw-5-down
			--side: -0.05em
			top: 1.3em
		&::before
			content: ''
			display: block
			absolute: full 0.625em
			border: 1px solid rgba($color-red-accent, 0)
			border-radius: inherit
			background:
				color: var(--color-red)
				image: url('partials/responses/images/chevron-right.svg')
				position: center
			transform: scale(1)
			transition:
				duration: 0.3s
				property: border-color, background-color, transform
			will-change: border-color, background-color, transform
		&:hover
			&::before
				background-color: var(--color-red-hover)
		&:focus
			outline: none
			&::before
				border-color: var(--color-red-accent)
		&:active
			&::before
				transform: scale(0.9)
		&_back
			left: var(--side)
			&::before
				transform: scaleX(-1)
			&:active
				&::before
					transform: scaleX(-1) scale(0.9)
		&_next
			right: var(--side)
		> span
			+visually-hidden
.response
	flex-grow: 1
	border-radius: 10px
	padding: 1em
	background-color: var(--color-blue)
	//&-header
	&-text
		+vw-5-down
			font-size: 0.875em
		@media #{vh-down(400px)}
			font-size: 0.875em
.responseHeader
	display: flex
	+vw-5-down
		flex-flow: column
		align-items: center
	@at-root a#{&}
		text-decoration: none
		border-radius: 1px
		box-shadow: 0 0 0 10px rgba($color-blue-hover, 0)
		transition:
			property: background-color, box-shadow
			duration: 0.3s
		&:focus
			outline: none
		&:hover,
		&:focus
			background-color: var(--color-blue-hover)
			box-shadow: 0 0 0 10px var(--color-blue-hover)
		&:hover
			%responseHeader_link_hovered-suffix
				&::after
					transform: rotate(0)
		%responseHeader_link-suffix
			&::after
				content: '→'
				display: inline-block
				transform: rotate(-45deg)
				transition: transform 0.3s
	//&--text
		flex-shrink: 0
	&-image
		margin-right: 1em
		border-radius: 100%
	&-title
		margin-y: 1em 0.3em
		font-size: 1.125em
		font-weight: 700
		line-height: 1.2
		+vw-5-down
			text-align: center
	&-suffix
		@extend %responseHeader_link-suffix
		@extend %responseHeader_link_hovered-suffix
		margin-y: 0
		line-height: 1.3
		+vw-5-down
			text-align: center
		@media #{vh-down(400px)}
			font-size: 0.8em
