.feedback
	+container
	display: flex
	flex-flow: column
	align-items: center
	&-title
		font-size: var(--font-size-h3)
		text-align: center
	&-action
		font-size: 1.4em

