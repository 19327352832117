$notifications-z: 1100
\:root
	--js-notification-animation-length: 300
	--notification-animation-length: 0.3s
.notifications
	fixed: top right z $notifications-z
	display: flex
	flex-flow: column
	align-items: flex-end
.notification
	--opened: translateX(0)
	--closed: translateX(calc(100% + 0.5em))
	display: flex
	align-items: flex-start
	margin: 0.5em
	border-radius: 4px
	border: 2px solid rgba(black, 0.25)
	padding: 0.75em
		left: 1em
		right: 0.75em
	max-width: 80vw
	font-size: 0.8em
	background-color: var(--bg)
	color: var(--color)
	animation: notificationShow var(--notification-animation-length) ease-in
	will-change: transform
	&.is-hidden
		transition: transform var(--notification-animation-length)
		transform: var(--closed)
	&.is-positive
		--bg: hsl(120, 100%, 18%)
		--color: white
	&.is-negative
		--bg: hsl(0, 100%, 22%)
		--color: white
	&::before
		content: ''
		absolute: bottom left right
		height: 3px
		background-color: rgba(black, 0.3)
		transform: scaleX(1)
			origin: left
		will-change: transform
		animation: notificationBarShrink var(--timeout) linear
	&-close
		position: relative
		flex-shrink: 0
		margin: 0
			y: -0.5em
			left: 0.5em
			right: -0.5em
		border: none
			radius: 2px
		size: 2.5em
		overflow: hidden
		color: transparent
		background-color: rgba(white, 0)
		opacity: 0.8
		transition:
			duration: 0.3s
			property: background-color
		&:hover
			background-color: rgba(white, 0.1)
		&:focus
			background-color: rgba(white, 0.3)
			outline: none
		&::before
			content: ''
			absolute: full
			background:
				image: url('components/notification/cross.svg')
				size: 1em
				position: center
@keyframes notificationShow
	from
		transform: var(--closed)
	to
		transform: var(--opened)
@keyframes notificationBarShrink
	from
		transform: scaleX(1)
	to
		transform: scaleX(0)
