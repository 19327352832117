=container
	margin-x: auto
	max-width: var(--container-width)
	padding-x: var(--container-padding-x)
=visually-hidden
	position: absolute
	clip: rect(0 0 0 0)
	width: 1px
	height: 1px
	margin: -1px
=link
	color: $color-red-text
	padding: 0.1em 0.25em
	border-radius: 2px
	background-color: rgba($color-red-text, 0)
	text-decoration-color: rgba($color-red-text, 1)
	transition:
		property: background-color, text-decoration-color
		duration: 0.3s
	&:hover
		color: $color-red-text-hover
	&:hover,
	&:focus
		background-color: rgba($color-red-text, 0.1)
		text-decoration-color: rgba($color-red-text, 0)
		+dark
			background-color: rgba($color-red-text, 0.05)
	&:focus
		outline: none
	+dark
		color: $color-red-text-at-dark
		&:hover
			color: $color-red-text-hover-at-dark
		&:hover,
		&:focus
			background-color: rgba($color-red-text-at-dark, 0.2)
@function aspectRatio($width, $height)
	@return $height / $width * 100%
