.staff
	+container
	display: flex
	flex-flow: column
	align-items: center
	&-title
		text-align: center
		font-size: var(--font-size-h3)
	&-levels
		width: 100%
		+vw-10-down
			max-width: 30em
	&--action
		margin-top: 2em
.staffLevels
	display: flex
	justify-content: center
	+vw-10-down
		flex-wrap: wrap
	&-item
		display: flex
		margin-y: 1em
		+vw-10-up
			flex-grow: 1
			flex-basis: 0
		+vw-10-down
			width: (100% / 2)
		+vw-6-down
			width: (100% / 2)
		> *
			flex-grow: 1
.staffLevel
	display: flex
	flex-flow: column
	align-items: center
	&-image
		margin-bottom: 1em
		+vw-5-down
			width: 40%
	&-label
		flex-grow: 1
		margin-y: 0
		text-align: center
		font-size: 1.5em
		font-weight: 700
		+vw-7-down
			font-size: 1.125em
		+vw-4-down
			font-size: 1em
	&-price
		margin-y: 0
		font-size: 1.5em
		> strong
			font-size: 3rem
			+vw-5-down
				font-size: 2.25rem
	&-suffix
		margin-y: 0
		+vw-4-down
			font-size: 0.8em
