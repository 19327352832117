@import 'source/partials/header/index'
@import 'source/partials/welcome/index'
@import 'source/partials/services/index'
@import 'source/partials/service/index'
@import 'source/partials/staff/index'
@import 'source/partials/introduction/index'
@import 'source/partials/turbocheck/index'
@import 'source/partials/warranty/index'
@import 'source/partials/responses/index'
@import 'source/partials/targeting/index'
@import 'source/partials/features/index'
@import 'source/partials/longread/index'
@import 'source/partials/feedback/index'
@import 'source/partials/footer/index'

.home
	//&-header
	//&-welcome
	&-services
		margin-bottom: 3em
	&-staff
		margin-bottom: 3em
		+vw-5-down
			margin-bottom: 3em
	&-introduction
		margin-bottom: 6em
	&-warranty
		margin-bottom: 6em
	//&-turbocheck
		//margin-bottom: 6em
	&-responses
		margin-bottom: 6em
	&-targeting
		margin-bottom: 6em
		+vw-5-down
			margin-bottom: 3em
	&-features
		margin-bottom: 6em
	&-longread
		//--modal-max-width: 120em
		margin-bottom: 6em
	&-feedback
		margin-bottom: 6em
		+vw-5-down
			margin-bottom: 3em
	&-footer
		margin-top: 3em
