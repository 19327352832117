.introduction
	+container
	+vw-9-up
		display: flex
	&--text
		flex-grow: 1
		padding-right: 1em
		p
			+vw-9-up
				max-width: 34em
	&-actions
		display: flex
		flex-flow: column
		width: 100%
		max-width: 15em
		+vw-9-down
			margin-x: auto
			margin-top: 2em
	&-action
		width: 100%
	&-actionsText
		text-align: center
		margin-y: 1em
	&--aside
		flex-shrink: 0
		width: 100%
		max-width: 25em
		text-align: center
		+vw-9-down
			margin-x: auto
			margin-top: 4em
.introductionAside
	&--head
		margin-bottom: 1em
	&-title
		margin-y: 0.25em
		font-size: 1.5em
	p
		margin-y: 0.5em
.introductionImage
	display: block
	margin-bottom: 1em
	@at-root a#{&}
		text-decoration: none
	figure
		margin: 0
	img
		display: block
		border-radius: 4px
		width: 100%
		height: auto
		box-shadow: 0 4px 10px rgba(black, 0.15)
	figcaption
		margin-y: 1em
