=dark
	@media (prefers-color-scheme: dark)
		@content
=light
	@media (prefers-color-scheme: light)
		@content

@function vw-down($bp)
	@return "(max-width: #{$bp - 1})"

@function vw-up($bp)
	@return "(min-width: #{$bp})"

@function vh-down($bp)
	@return "(max-height: #{$bp - 1})"

@function vh-up($bp)
	@return "(min-height: #{$bp})"

// Works with default container width only
// TODO generate media query using container width custom property
$bp-container: $container-width
=vw-container-up
	@media #{vw-up($bp-container)}
		@content
=vw-container-down
	@media #{vw-down($bp-container)}
		@content

$bp-11: 1100px
=vw-11-up
	@media #{vw-up($bp-11)}
		@content
=vw-11-down
	@media #{vw-down($bp-11)}
		@content

$bp-10: 1024px
=vw-10-up
	@media #{vw-up($bp-10)}
		@content
=vw-10-down
	@media #{vw-down($bp-10)}
		@content

$bp-9: 900px
=vw-9-up
	@media #{vw-up($bp-9)}
		@content
=vw-9-down
	@media #{vw-down($bp-9)}
		@content

$bp-7: 768px
=vw-7-up
	@media #{vw-up($bp-7)}
		@content
=vw-7-down
	@media #{vw-down($bp-7)}
		@content

$bp-6: 640px
=vw-6-up
	@media #{vw-up($bp-6)}
		@content
=vw-6-down
	@media #{vw-down($bp-6)}
		@content

$bp-5: 500px
=vw-5-up
	@media #{vw-up($bp-5)}
		@content
=vw-5-down
	@media #{vw-down($bp-5)}
		@content

$bp-4: 400px
=vw-4-up
	@media #{vw-up($bp-4)}
		@content
=vw-4-down
	@media #{vw-down($bp-4)}
		@content
