.form
	&--input
		margin: 0
		padding: 0
		border: 0
		&:not(:last-child)
			margin-bottom: 1em
	&-input
		border-radius: 10px
		border: 2px solid transparent
		width: 100%
		padding: 0.5em 1em
		background-color: var(--color-gray)
		transition:
			duration: 0.3s
			property: border-color
		&:focus:not(:disabled)
			outline: none
			border-color: var(--color-gray-focus)
		&:disabled
			opacity: 0.5
			cursor: progress
	&--label
		padding-left: 1em
	&-label
		font-size: 0.75em
	&--action
		display: flex
		justify-content: center
	&-action
		width: 100%
