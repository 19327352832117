.button
	display: inline-block
	border-radius: 10px
	padding: 0.5em 1.5em
	color: white
	background-color: var(--background-color)
	text-align: center
	transform: scale(1)
	transition:
		property: background-color, transform, border-color, box-shadow
		duration: 0.3s
	@at-root a#{&}
		text-decoration: none
	&:hover:not(:disabled)
		background-color: var(--background-color-hover)
	&:focus:not(:disabled)
		outline: none
		border-color: var(--border-color-focus)
		box-shadow: 0 0 0 1px var(--box-shadow-color)
	&:active:not(:disabled)
		transform: scale(0.95)
	&:disabled
		opacity: 0.5
		cursor: progress
	&._color

		=border($color)
			border: 2px solid rgba($color, 0)
		=border-focus($color)
			border: 2px solid $color

		=box-shadow($color)
			box-shadow: 0 0 24px $color

		=make($border-color, $box-shadow-color)
			+border($border-color)
			+box-shadow(rgba($box-shadow-color, 0))
			&:hover:not(:disabled)
				+box-shadow(rgba($box-shadow-color, 0.4))
			&:focus:not(:disabled)
				+border-focus($border-color)
				+box-shadow(rgba($box-shadow-color, 0.4))

		&-primary
			--background-color: hsl(216, 100%, 44%)
			--background-color-hover: hsl(216, 100%, 55%)
			--border-color-focus: hsl(216, 100%, 30%)
			--box-shadow-color: hsla(216, 100%, 44%, 0.4)
			$border-color: hsl(216, 100%, 30%)
			$box-shadow-color: hsl(216, 100%, 30%)
			+make($border-color, $box-shadow-color)

		&-secondary
			--background-color: var(--color-red)
			--background-color-hover: var(--color-red-hover)
			--border-color-focus: var(--color-red-accent)
			--box-shadow-color: var(--color-red-accent)
			$border-color: $color-red-accent
			$box-shadow-color: $color-red-accent
			+make($border-color, $box-shadow-color)
