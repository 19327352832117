.features
	+container
	display: grid
	+vw-6-up
		grid-template-columns: repeat(2, 1fr)
	+vw-10-up
		grid-template-columns: repeat(3, 1fr)
	&-item
		margin-bottom: 2em
.feature
	&--icon
		display: inline-block
		background-color: $color-blue
		border-radius: 20em
		padding: 0.9em
	//&-icon
	&-title
		margin-y: 0.5em
		font-size: 1.25em
	&-text
		margin-y: 0.25em
		max-width: 25em
		padding-right: 2em
