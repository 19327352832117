.welcome
	+container
	position: relative
	display: flex
	flex-flow: column
	align-items: flex-start
	min-height: 25em
	+vw-9-down
		margin-x: auto
		max-width: 36em
	&-title
		relative: z 2
		margin-y: 0.8em 0.3em
		max-width: 12em
		font-size: var(--font-size-h2)
		line-height: 1.4
	&-description
		relative: z 2
		max-width: 23em
	&-actions
		relative: z 2
		margin-top: 1em
	&-action
		margin-y: 0.5em
		//&._primary
		&._secondary
			+vw-5-up
				display: none
	&-image
		relative: z 1
		display: block
		+vw-9-up
			absolute: top bottom right
		img
			display: block
			height: 100%
			max-width: 100%
