.services
	+container
	&-title
		text-align: center
		font-size: var(--font-size-h3)
	&_special
		--container-width: 60em
.servicesGrid
	$p: 1.25em
	display: grid
	grid-template-columns: repeat(3, 1fr)
	grid-auto-rows: auto
	grid-gap: $p
	margin-bottom: $p
	&_special
		grid-template-columns: repeat(2, 1fr)
		%servicesGrid_special-item
			&:nth-last-child(1):nth-child(odd)
				+vw-7-up
					grid-column: 1 / -1
	+vw-10-down
		grid-template-columns: repeat(2, 1fr)
	+vw-7-down
		grid-template-columns: repeat(1, 1fr)
	&-item
		@extend %servicesGrid_special-item
		display: flex
		flex-flow: column
		> *
			flex-grow: 1
		%servicePreview
			width: 100%
			margin-x: auto
			+vw-7-up
				max-width: 30em
		+vw-10-down
			&:nth-last-child(1):nth-child(odd)
				grid-column: 1 / -1
.servicePreview
	@extend %servicePreview
	--focus-border: 3px

	@function shadow-border($color)
		@return inset 0 0 0 var(--focus-border) $color
	@function shadow($color)
		@return 0 4px 24px $color

	$border-color: $color-blue-accent
	$shadow-color: hsl(203, 100%, 91%)

	$border-color-at-dark: $color-blue-focus-at-dark
	$shadow-color-at-dark: $color-blue-hover-at-dark

	position: relative
	display: grid
	grid-template-columns: auto min-content
	grid-template-rows: min-content auto min-content
	border-radius: 10px
	padding: 1em
		bottom: 0.75em
	background-color: rgba($color-blue, 0.6)
	box-shadow: shadow-border(rgba($border-color, 0)), shadow(rgba($shadow-color, 0))
	transition:
		property: background-color, border-color, box-shadow
		duration: 0.3s
	+dark
		--focus-border: 1px
		background-color: rgba($color-blue-at-dark, 0.3)
		box-shadow: shadow-border(rgba($border-color-at-dark, 0)), shadow(rgba($shadow-color-at-dark, 0))
	@at-root label#{&}, a#{&}
		border: 2px solid var(--color-blue-accent)
		background-color: var(--color-blue)
		text-decoration: none
		cursor: pointer
		&:hover,
		&:focus
			%servicePreview_hover-action
				&::after
					transform: scaleX(1)
		&:hover
			background-color: var(--color-blue-hover)
			border-color: var(--color-blue-accent)
			box-shadow: shadow-border(rgba($border-color, 0)), shadow($shadow-color)
			+dark
				box-shadow: shadow-border(rgba($border-color-at-dark, 0)), shadow($shadow-color-at-dark)
		&:focus
			outline: none
			//border-color: var(--color-blue-focus)
			border-color: $border-color
			box-shadow: shadow-border($border-color), shadow($shadow-color)
			+dark
				box-shadow: shadow-border($border-color-at-dark), shadow($shadow-color-at-dark)
	&-title
		margin-y: 0 0.25em
		grid-column: 1 / -1
		font-size: 1.3em
		font-weight: 700
	&-description
		grid-column: 1
		margin:
			y: 0 0.5em
		font-size: 0.875em
		line-height: 1.3
	&--image
		margin-top: auto
		grid-column: 2
		grid-row: 2 / -1
	&-image
		margin-left: 0.5em
	&--action
		//absolute: bottom 0.75em left 1em
		grid-row: 3
	&-action
		@extend %servicePreview_hover-action
		position: relative
		font-size: 0.7em
		letter-spacing: 0.04em
		font-weight: 700
		font-family: system-ui
		text-transform: uppercase
		color: var(--color-blue-focus)
		+dark
			color: lighten(saturate($color-blue-focus-at-dark, 30%), 25%)
		&::after
			content: ''
			display: block
			absolute: top 100% left
			width: 100%
			border-bottom: 1px solid
			transform: scaleX(0)
				origin: left
			transition: transform 0.2s
			will-change: transform
.serviceModal
	--modal-max-width: 60em
	--x: 1em
	--y: 1em
	+vw-5-down
		--x: 0.5em
		--y: 0.5em
	.modal
		&--close
			max-width: var(--modal-max-width)
			margin-top: var(--y)
		&-close
			right: var(--x)
		&-outer
			padding-x: var(--x)
		&-inner
			margin-bottom: var(--y)
			padding: 2em
				top: 1em
			+vw-9-down
				padding: 0 1em
