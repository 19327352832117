$bp-vh: 550px
.serviceLayout
	&-header
		margin-bottom: 1em
		+vw-9-up
			display: grid
			grid-template-columns: auto 23em
			padding-top: 1em
			@media #{vh-up($bp-vh)}
				sticky: top
				background-color: var(--body-bg)
			.serviceModal &
				grid-template-columns: auto 40%
	&-title,
	&-stickyTitle
		grid-column: 1 / -1
		font-size: var(--font-size-h3)
	&-title
		margin-y: 0 0.5em
		+vw-9-down
			display: none
	&-stickyTitle
		margin-y: 0.25em 0
		padding-y: 0.5em 0
		+vw-9-up
			display: none
		+vw-9-down
			sticky: top z 1
			margin-top: 0
			background-color: var(--body-bg)
	&-head
		grid-row: 2
		grid-column: 2
		padding-left: 1em
		+vw-9-down
			display: none
	&-stickyAction
		border-top: 1px solid hsl(0, 0%, 90%)
		padding-top: 0.75em
		+dark()
			border-top-color: hsl(0, 0%, 50%)
		+vw-9-up
			display: none
		+vw-9-down
			@media #{vh-up($bp-vh)}
				sticky: bottom z 1
				width: 100%
				background-color: var(--body-bg)
	&-text
		grid-row: 2
		grid-column: 1
		max-width: 40em
		> :first-child
			margin-top: 0
		> :last-child
			margin-bottom: 0
	//&-pricing
	&-textExt
		h1
			font-size: var(--font-size-h1)
		h2
			font-size: var(--font-size-h2)
		h3
			font-size: var(--font-size-h3)
		h4
			font-size: var(--font-size-h4)
		p
			max-width: 50em
.serviceHead
	&-title
		margin-y: 0 0.5em
		font-size: var(--font-size-h4)
	&-value
		margin-y: 0 0.5em
		font-size: 1.125em
		+vw-9-down
			margin-bottom: 0
		strong
			font-weight: 700
	&-action
		display: flex
		flex-flow: column
		justify-content: flex-end
		align-items: flex-start
		margin-y: 0
		min-height: 6.5em
		margin-right: 2em
		padding-y: 1em
		+vw-9-up
			margin-y: 1em
			min-height: 5em
			padding-y: 0
		&:not(:last-child)
			margin-bottom: 1em
			border-bottom: 1px solid hsl(0, 0%, 90%)
			padding-bottom: 1em
			+dark()
				border-bottom-color: hsl(0, 0%, 50%)
.servicePricing
	&-title
		margin-y: 0 0.5em
		font-size: var(--font-size-h4)
	&-note
		max-width: 35em
	&-value
		margin-y: 0 0.5em
		font-size: 1.2em
		font-weight: 700
	&-priceLevels
		font-size: 1.125em
.priceLevels
	display: flex
	flex-wrap: wrap
	max-width: 35em
	&-item
		width: 50%
		margin-y: 1.2em
.priceLevel
	&-title
		font-weight: 700
	&-value
		margin: 0
.orderGateway
	&-trigger,
	&--form
		&[hidden]
			display: none
	&--form
		display: flex
		align-items: flex-end
		align-self: stretch
	&--input
		position: relative
		flex-grow: 1
		\:root &
			margin-bottom: 0
	&--label
		absolute: bottom 100% left
	&-label
		white-space: nowrap
	&--action
		flex-shrink: 0
	&-input
		border-right-radius: 0
		border-right-width: 0
	&-action
		border-left-radius: 0
		transform-origin: left center
