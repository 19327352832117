.longread
	+container
	position: relative
	+vw-5-down
		--container-padding-x: 0
	&--head
		text-align: center
		font-size: 1.1em
		+vw-5-up
			font-size: 1.5em
		+vw-5-down
			padding-x: 0.25em
	&-title
		+vw-5-up
			font-size: 1.5em
	&--text
		relative: z 2
		max-width: 34em
		+vw-5-down
			padding-x: 1em
		&::before
			content: ''
			absolute: top left
			size: 100%
			background-color: var(--body-bg)
			opacity: 0.6
		> *
			relative: z 1
	&-link
		+link
		text-decoration: underline
		cursor: pointer
		+vw-5-down
			display: block
			text-align: center
	&--image
		absolute: bottom 4em right z 1
.longreadModal
	--modal-max-width: 60em
	--x: 1em
	--y: 1em
	+vw-5-down
		--x: 0.5em
		--y: 0.5em
	.modal
		&--close
			max-width: var(--modal-max-width)
			margin-top: var(--y)
		&-close
			right: var(--x)
		&-outer
			padding-x: var(--x)
		&-inner
			margin-bottom: var(--y)
			padding: 2em
				top: 1em
			+vw-9-down
				padding: 0 1em
	&-title
		margin-top: 0
		+vw-7-up
			font-size: 2.5em
