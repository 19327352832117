.turbocheckContainer
	padding-y: 5em
	background-color: white
	color: black
	+vw-5-down
		padding-y: 2em 3em
.turbocheck
	+container
	+vw-7-up
		display: grid
		grid-template-columns: 1fr 1fr
	&--title
		grid-row: 1
		grid-column: 1 / span 2
	&-title
		margin: 0
		line-height: 1.2
		font-size: 2.5em
		+vw-7-down
			font-size: 2em
	&--list
		grid-row: 2
		grid-column: 1
		margin-top: 1em
		+vw-7-up
			padding-right: 2em
	//&-list
	&--image
		grid-row: 1 / span 3
		grid-column: 2
		display: flex
		align-items: center
		justify-content: center
	&-image
		max-width: 100%
		height: auto
		+vw-7-down
			width: 100%
			max-width: 33em
			margin: 2em
	&--action
		grid-row: 3
		grid-column: 1
		margin-top: 1em
		+vw-7-down
			text-align: center
	//&-action
