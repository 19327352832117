.footer
	+container
	--container-width: 75em
	--container-padding-x: 0
	padding-top: 2em
.footerLayout
	display: flex
	+vw-9-down
		flex-flow: column
		align-items: center
	+vw-9-up
		flex-wrap: wrap
		justify-content: center
	&-section
		&._payments,
		&._links
			+vw-9-up
				width: 50%
				max-width: 23em
		&._payments
			margin-bottom: 3em
			+vw-9-up
				margin-right: 2em
		&._links
			+vw-9-up
				padding-left: 2em
		&._headerLinks
			+vw-9-up
				display: none
		&._sitemap
			margin-top: 3em
.footerPayments
	&-methods
		+vw-9-down
			display: flex
			flex-wrap: wrap
			justify-content: center
	&-badges
		margin-top: 1em
.footerBadges
	+vw-9-down
		display: flex
		justify-content: center
	&-item
		&:not(:last-child)
			margin-right: 0.5em
.footerLinks,
.footerSitemap
	a
		+link
.footerLinks
	+vw-9-down
		display: flex
		flex-flow: column
		align-items: center
	&-item
		margin-y: 0.5em
.footerSitemap
	display: flex
	flex-wrap: wrap
	justify-content: center
	text-align: center
	margin-y: 0
	padding-y: 1em
	+vw-7-down
		background-color: rgba(black, 0.03)
		+dark
			background-color: rgba(white, 0.02)
	&-item
		margin: 0.5em 0.75em
